import { generalAppInfo } from "./constants";

export const LOCALHOST = "localhost";
export const BETA_BASEURL = "beta.mytruq.com";
export const PROD_BASEURL = "mytruq.com";

export const getBaseUrl = () => {
  let analytics = generalAppInfo.BACKOFFICE_BASE_URL, web = generalAppInfo.API_BASE_URL;
  if (window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) {
    analytics = generalAppInfo.BACKOFFICE_STAGING_BASE_URL;
    web = generalAppInfo.API_STAGING_BASE_URL;
  } else if (window.location.hostname === PROD_BASEURL) {
    analytics = generalAppInfo.BACKOFFICE_BASE_URL;
    web = generalAppInfo.API_BASE_URL;
  }
  return { analytics, web };
};

export const getBaseUrlEnv = () => {
  if (window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) return "SANDBOX";
  else return "PRODUCTION";
};
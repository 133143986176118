import {Fragment} from 'react';
import {Toaster} from "react-hot-toast";
import LandingPage from './LandingPage';

function App() {
  return (
    <Fragment>
      <Toaster containerStyle={{zIndex: '99999999'}} position="top-right" reverseOrder={false}/>
        <LandingPage/>
    </Fragment>
  );
}

export default App;
